:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.description {
  display: inherit;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.search {
  display: inherit;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  max-width: var(--max-width);
  margin: 45px;
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.search form{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.search input{
  width: 100%;
  height: 30px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.result
{
  display: flex;
  justify-content: space-around;
  max-width: var(--max-width);
}

.card {
  display: flex;
  justify-content: space-around;
  max-width: var(--max-width);
  padding: 1rem 1.2rem;
  margin-bottom: 10px;
  border-radius: var(--border-radius);
  background-color: rgba(var(--card-rgb), 0.5);
  border: 1px solid rgba(var(--card-border-rgb), 0.3);
  transition: background 200ms, border 200ms;
}

.card h1{
  color: rgba(254, 165, 37, 1);
  margin-bottom: 10px;
  margin-top: 10px;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.cardcontent{
  max-width: var(--max-width);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--callout-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}